import React, { useState, useEffect } from "react";
import API from "../utils/API";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0.7),
      width: "60ch",
    },
  },
}));

const InterestedCustomerDetails = (props) => {
  const classes = useStyles();
  // const [value, setValue] = React.useState("Controlled");

  const location = useLocation();

  console.log(location.state);

  const { query, query2, query3, phonenumber } = location.state;
  const history = useHistory();

  const [name, setName] = useState();

  const [number, setNumber] = useState(phonenumber);
  const [address, setAddress] = useState();
  const [pincode, setPincode] = useState();
  const [locality, setLocality] = useState();
  const [state, setState] = useState();
  const [email, setEmail] = useState();

  // const handleChange = (event) => {
  //   setValue(event.target.value);

  // };

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }

    API({
      url: `/customerdetail/${phonenumber}`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        style={{ marginTop: -20 }}
      >
        <h3 style={{ color: "#555555" }}>Customer Details</h3>
        <div>
          <br />
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="standard-textarea"
            label="Customer Name"
            placeholder="Enter name here"
            multiline
            name="customername"
          />
          <br />
          <TextField
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            id="standard-textarea"
            label="Customer Number"
            placeholder="Enter number here"
            multiline
            type="number"
            name="customernumber"
          />
          <br />
          <TextField
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            id="standard-multiline-static"
            label="Customer Address"
            placeholder="Enter address here"
            rows={4}
            multiline
          />
          <br />
          <TextField
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            id="standard-textarea"
            label="Customer Pincode"
            placeholder="Enter Pincode here"
            multiline
            type="number"
          />
          <br />
          <TextField
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
            id="standard-textarea"
            label="Customer Locality"
            placeholder="Enter locality here"
            multiline
          />
          <br />
          <TextField
            value={state}
            onChange={(e) => setState(e.target.value)}
            id="standard-textarea"
            label="Customer State"
            placeholder="Enter state here"
            multiline
          />
          <br />
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="standard-textarea"
            label="Customer Email"
            placeholder="Enter email here"
            multiline
            type="email"
          />
          <br />
        </div>

        <br />

        <Button variant="contained" color="primary">
          <Link
            to={{
              pathname: "/actionleadgeneration/",
              state: {
                categoryHeadId: query,
                categoryId: query2,
                subCategoryId: query3,
                cm: {
                  customerName: name,
                  customerNumber: number,
                  customerAddress: address,
                  pincode: pincode,
                  locality: locality,
                  state: state,
                  email: email,
                },
              },
            }}
            style={{ textDecoration: "none", color: "white" }}
          >
            Next
          </Link>
        </Button>
      </form>
    </>
  );
};

export default withRouter(InterestedCustomerDetails);
