import React, { useEffect } from "react";
import "./App.css";
import CrmOutbond from "./pages/CrmOutbound.js";
import Navbar from "./components/Navbar";
import User from "./pages/user";
// import XpressCheckout from "./pages/XpressCheckout";
import XpressCheckout from "./pages/xpressCheckout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Logout from "./pages/Logout";
import LoginCRM from "./pages/LoginCRM";
import VendorPayment from "./pages/VendorPayment";
import Leadgenerations from "./pages/Leadgenerations";
import InterestedCustomerDetails from "./pages/InterestedCustomerDetails";
import ActionLeadgeneration from "./pages/ActionLeadgeneration";
import LeadReport from "./pages/LeadReport";
import AMCReport from "./pages/AMCReport";
import Notcontacted from "./pages/Notcontacted";
import Notinterested from "./pages/Notinterested";
import VendorBalance from "./pages/VendorBalance";
import Newserviceoutbond from "./pages/Newserviceoutbond";
import Newserviceintrestedcustomer from "./pages/Newserviceinterestedcustomer";
import NotInterestedUsers from "./pages/NotInterestedUsers";
import NotContactedUsers from "./pages/NotContactedUsers";
import InboundLeadgenerations from "./pages/InboundLeadgenerations";
import InboundCustomerDetails from "./pages/InboundCustomerDetails";
import InboundAction from "./pages/InboundAction";
import InboundSource from "./pages/InboundSource";
import CrmInbound from "./pages/CrmInbound";
import Outbound from "./pages/Outbound.js";
import InboundComplaint from "./pages/InboundComplaint.js";
import Complaints from "./pages/Complaints.js";
import BookingSource from "./pages/BookingSource.js";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import ServiceBookings from "./pages/ServiceBookings";
import WalletReport from "./pages/WalletReport";
import  TechnicianCollection from "./pages/TechnicianCollection";
import TechnicianReport from "./pages/TechnicianReport";


function App() {
  const history = useHistory();

  const redirectToPage = async () => {
    await Auth.currentAuthenticatedUser().then((u) => {
      if (u.signInUserSession) {
        localStorage.setItem(
          "tokenStore",
          u.signInUserSession.accessToken.jwtToken
        );
      } else {
        window.onunload = () => {
          // Clear the local storage
          window.MyStorage.clear();
        };
      }
    });
  };
  useEffect(() => {
    redirectToPage();
  }, []);

  {
    /*
   window.onbeforeunload = function() {
    localStorage.clear();
}

  window.onunload = () => {
   // Clear the local storage
   window.MyStorage.clear()
}
*/
  }
  //  const [today,setToday]=useState("");

  //   useEffect(()=>{

  //     const dateNow = new Date();
  //     const year = dateNow.getFullYear();
  //     const monthWithOffset = dateNow.getUTCMonth() + 1;
  //     const month =
  //       monthWithOffset.toString().length < 2
  //         ? `0${monthWithOffset}`
  //         : monthWithOffset;
  //     const date =
  //       dateNow.getUTCDate().toString().length < 2
  //         ? `0${dateNow.getUTCDate()}`
  //         : dateNow.getUTCDate();

  //     const materialDateInput = `${year}-${month}-${date}`;
  //     setToday(materialDateInput);

  //   },[])
  return (
    // ---------------------new-------------------//

    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginCRM />
          </Route>
          <React.Fragment>
            <Navbar />

            <Route exact path="/crm-outbound">
              <CrmOutbond />
            </Route>

            <Route exact path="/users">
              <User />
            </Route>
            <Route exact path="/express-booking">
              <XpressCheckout />
            </Route>
            <Route exact path="/vendor-payment">
              <VendorPayment />
            </Route>
            <Route exact path="/vendor-balance">
              <VendorBalance />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/lead-generations">
              <Leadgenerations />
            </Route>
            <Route exact path="/notinterested-users">
              <NotInterestedUsers />
            </Route>
            <Route exact path="/notcontacted-users">
              <NotContactedUsers />
            </Route>
            <Route exact path="/interested-customerdetails">
              <InterestedCustomerDetails />
            </Route>
            <Route exact path="/newservice-outbond">
              <Newserviceoutbond />
            </Route>
            <Route exact path="/newservice-intrestedcustomer">
              <Newserviceintrestedcustomer />
            </Route>

            <Route exact path="/actionlead-generation">
              <ActionLeadgeneration />
            </Route>
            <Route exact path="/notcontacted">
              <Notcontacted />
            </Route>
            <Route exact path="/notinterested">
              <Notinterested />
            </Route>
            <Route exact path="/lead-report">
              <LeadReport />
            </Route>
            <Route exact path="/amc-report">
              <AMCReport />
            </Route>
            <Route exact path="/inbound-leadgenerations">
              <InboundLeadgenerations />
            </Route>
            <Route exact path="/inbound-customerdetails">
              <InboundCustomerDetails />
            </Route>
            <Route exact path="/inbound-action">
              <InboundAction />
            </Route>
            <Route exact path="/inbound-source">
              <InboundSource />
            </Route>
            <Route exact path="/crm-inbound">
              <CrmInbound />
            </Route>
            <Route exact path="/outbound">
              <Outbound />
            </Route>
            <Route exact path="/inbound-complaint">
              <InboundComplaint />
            </Route>
            <Route exact path="/complaints">
              <Complaints />
            </Route>
            <Route exact path="/booking-source">
              <BookingSource />
            </Route>
            <Route exact path="/service-bookings">
              <ServiceBookings />
            </Route>
            <Route exact path="/wallet-report">
              <WalletReport/>
            </Route>
           <Route exact path="/technician-collection">
             < TechnicianCollection/>
           </Route>
           <Route exact path="/technician-report">
             <TechnicianReport/>
           </Route>
          </React.Fragment>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
