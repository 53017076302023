import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import API from "../utils/API";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport variant="contained" color="secondary" />
    </GridToolbarContainer>
  );
}

const LeadReport = () => {
  const history = useHistory();

  const [leadreport, setleadreport] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [startDateFromButtonClick, setStartDateFromButtonClick] = useState();

  const [endDateFromButtonClick, setEndDateFromButtonClick] = useState();

  const [loading, setLoading] = useState(false);

  const options = { month: "long", day: "numeric", year: "numeric" };

  const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "cHead",
      headerName: "Category Head",
      width: 200,
      valueFormatter: (value) => value.row?.cHead?.name,
    },
    {
      field: "cat",
      headerName: "Category",
      width: 200,
      valueFormatter: (value) => value.row?.cat?.name,
    },
    {
      field: "subCat",
      headerName: "SubCategory",
      width: 200,
      valueFormatter: (value) => value.row?.subCat?.name,
    },
    {
      field: "leadCategoryId",
      headerName: "Lead Type",
      width: 180,
      valueFormatter: (value) => value.row?.leadCategory?.name,
    },
    {
      field: "date",
      headerName: "Entry Date",
      width: 200,
      valueFormatter: ({value}) => dateFormatter.format(new Date(value)),
    },
    {
      field: "callType",
      headerName: "Call Type",
      width: 140,
      valueFormatter: (value) => value.row?.callType
    },
    {
      field: "source",
      headerName: "Source",
      width: 140,
      valueFormatter: (value) => value.row?.source?.name,
    },

    // { field: "discountValue", headerName: "Discount Value", width: 160},
    {
      field: "callbackDate",
      headerName: "Callback Date",
      width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
      valueFormatter: (value) => value.row?.cm?.customerName,
    },
    {
      field: "customerNumber",
      headerName: "Customer Mobile",
      width: 200,
      valueFormatter: (value) => value.row?.cm?.customerNumber,
    },
    {
      field: "createdPerson",
      headerName: "Created By",
      width: 150,
      valueFormatter: (value) => value.row?.createdPerson?.name,
    },
    { field: "remarks", headerName: "Remarks", width: 1000 },
  ];

  const handleClick = () => {
    setLoading(!loading);
    setStartDateFromButtonClick(startDate);
    setEndDateFromButtonClick(endDate);
    setleadreport({});
  };

  useEffect(() => {
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    var today = new Date();

    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    let lastdate = daysInMonth(today.getMonth() + 1, today.getFullYear());
    if (month !== 10 && month !== 11 && month !== 12) {
      month = `0${month}`;
    }
    console.log(month);
    console.log(year);
    console.log(lastdate);
    const start = `${year}-${month}-01`;
    const end = `${year}-${month}-${lastdate}`;
    console.log(start);
    console.log(end);

    setStartDate(start);
    setEndDate(end);
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }
    if (startDate !== "" && endDate !== "") {
      API({
        url: `/callbackaction?startDate=${startDateFromButtonClick}&endDate=${endDateFromButtonClick}`,
        headers: { Authorization: `Bearer ${storedToken}` },
      })
        .then((res) => {
          console.log(res);
          setLoading(false);
          // setCustomerservices(res.data);
          console.log(res.data);
          setleadreport(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [startDateFromButtonClick, endDateFromButtonClick, loading]);

  return (
    <Box alignItems="center" style={{ width: "87%", marginLeft: "15rem" }}>
      <div>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <TextField
            onChange={(e) => setStartDate(e.target.value)}
            id="date"
            label="Start Date"
            type="date"
            style={{ transform: "translate(-15%,-20%)" }}
            InputLabelProps={{ shrink: true }}
            value={startDate}
          />

          <TextField
            id="date"
            label="End Date"
            type="date"
            style={{ transform: "translate(8%,-20%)" }}
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <br />

          <Button
            type="submit"
            style={{
              padding: "4px",
              margin: "5px",
              marginTop: "20px",
            }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Submit
          </Button>
        </form>
        <br />

        <div
          style={{
            height: 450,
            width: "100%",
            background: "transparent",
            color: "black",
            marginTop: 20,
            marginLeft: 0,
          }}
        >
          {leadreport.length > 0 && (
            <DataGrid
              rows={leadreport}
              columns={columns}
              pageSize={10}
              sortingOrder={["desc", "asc"]}
              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
              components={{
              Toolbar: CustomToolbar,
              }}
            />
          )}

          {loading ? <CircularProgress style={{ marginTop: 50 }} /> : ""}
        </div>
      </div>
    </Box>
  );
};

export default LeadReport;
