import React, { useEffect } from "react";
//import makeStyles from '@mui/styles/makeStyles';
import { makeStyles } from '@mui/styles';
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import API from "../utils/API";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import NativeSelect from "@mui/material/NativeSelect";
import Radio from "@mui/material/Radio";
import APIOld from "../utils/APIOld";
// import { FormHelperText } from "@mui/material";
// import TextField from "@mui/material/TextField";
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
// import { Typography } from '@material-ui/core';
// import { number } from "yup/lib/locale";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  select: {
    minHeight: 30,
    background: "#fafafa",
    border: "1px",
    borderBottom: "1px",
    outline: "1px",
    color: "#555555",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    height: "19px",
    lineHeight: "19.0016px",
    margin: "0px",
    padding: "6px 0px 7px",
    width: "366px",
  },
}));

const Newserviceoutbond = () => {
  const classes = useStyles();
  const history = useHistory();

  const [categoryHead, setcategoryHead] = React.useState();
  const [category, setcategory] = React.useState();
  const [subcategory, setSubcategory] = React.useState();
  const [servicetype, setServiceType] = React.useState();

  const [query, setQuery] = React.useState("1");

  const [query2, setQuery2] = React.useState("7");

  const [query3, setQuery3] = React.useState("2146");

  const [query4, setQuery4] = React.useState("63");
  const [questionnaireData, setQuestionnaireData] = React.useState();


  const [selectedValue, setSelectedValue] = React.useState("d");









  const handleChangeradio = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChange = (event) => {
    var value = event.target.value;

    setQuery(value);

    // console.log(value);

    // console.log("query is ", query);
  };

  const handleChange2 = (event) => {
    var value2 = event.target.value;

    // console.log("this is value ", value2);
    setQuery2(value2);

    // console.log("query 2 is ", query2);
  };

  const handleChange3 = (event) => {
    var value3 = event.target.value;
    setQuery3(value3);

    const categoryHeadId=query
    const categoryId=query2
    const subCategoryId=query3
  const serviceTypeId=query4

  let data = new FormData();
  data.append('categoryHeadId', categoryHeadId);
  data.append('categoryId', categoryId);
  data.append('subCategoryId', subCategoryId);
  data.append('serviceTypeId', serviceTypeId );
   data.append('parentId', 0);
   data.append('level', 0);
  data.append('type', 'json');

  // console.log(data);
  // console.log('raj'+ categoryId)
  APIOld({
    url: `getQuestionnaireList.html`,
    headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  },
    method:'POST',
    data:data
  })
    .then((res) => {
      // console.log(res);
     setQuestionnaireData(res.data.questionnaire);


    })
    .catch((err) => {
      // console.log(err);
    });



    // console.log("this is value ", value3);

    // console.log("query 3 is ", query3);
  };

  useEffect(() => {

    const categoryHeadId=query
    const categoryId=query2
    const subCategoryId=query3
    const serviceTypeId=query4

  let data = new FormData();
  data.append('categoryHeadId', categoryHeadId);
  data.append('categoryId', categoryId);
  data.append('subCategoryId',subCategoryId );
  data.append('serviceTypeId', serviceTypeId );
   data.append('parentId', 0);
   data.append('level', 0);
  data.append('type', 'json');

  // console.log(data);
  APIOld({
    url: `getQuestionnaireList.html`,
    headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  },
    method:'POST',
    data:data
  })
    .then((res) => {
      // console.log(res);
     setQuestionnaireData(res.data.questionnaire);

      setQuery4(res.data.questionnaire[0].answer);

    })
    .catch((err) => {
      // console.log(err);
    });

  }, []);

  const handleChange4 = (event) => {
    var value4 = event.target.value;

    // console.log("this is value 4 ", value4);
    setQuery4(value4);

    // console.log("query 4 is ", query4);
  };

  const location = useLocation();
  const { phonenumber } = location.state;

  var path = {};

  if (selectedValue === "a") {
    path = {
      pathname: "/interestedcustomerdetails/",
      state: {
        query: query,
        query2: query2,
        query3: query3,
        query4: query4,
        phonenumber: phonenumber,
      },
    };
  }

   else if (selectedValue === "c") {
    path = {
      pathname: "/notinterestedusers",
      state: {
        phonenumber: phonenumber,
      },
    };
  }
  else if (selectedValue === "d") {
    path = {
      pathname: "/newservice-intrestedcustomer/",
      state: {
        query: query,
        query2: query2,
        query3: query3,
        query4: query4,
        phonenumber: phonenumber,
      },
    };
  }

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }
    API({
      url: `/categoryHead/1`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        // console.log(res);

        // setCustomerservices(res.data);
        // console.log("Head data",res.data);
        setcategoryHead(res.data);
      })
      .catch((err) => {
        // console.log("Head error",err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }

    API({
      url: `/category/1`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        // console.log(res);

        // setCustomerservices(res.data);
        // console.log(res.data);
        setcategory(res.data);
      })
      .catch((err) => {
        // console.log("Category error",err);
        if (err.response.status === 400 || err.response.status === 401) {
          history.push("/");
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }
    API({
      url: `/category/7`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        // console.log(res);

        // setCustomerservices(res.data);
        // console.log(res.data);
        setSubcategory(res.data);
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 400 || err.response.status === 401) {
          history.push("/");
        }
      });
    // eslint-disable-next-line
  }, []);
// Testing
  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    API({
      url: `category/${query}`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        // console.log(res);

        // setCustomerservices(res.data);
        // console.log("Hi", res.data);
        setcategory(res.data);
        var q2 = res.data[0].master.id;
        var value = `${q2}`;
        setQuery2(value);
      })
      .catch((err) => {
        // console.log(err);
      });
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    API({
      url: `category/${query2}`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        // console.log(res);

        // setCustomerservices(res.data);
        // console.log("Hey", res.data);
        setSubcategory(res.data);
        var q3 = res.data[0].master.id;
        var value = `${q3}`;
        setQuery3(value);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [query2]);

  // useEffect(() => {
  //   const storedToken = localStorage.getItem("tokenStore");

  //   API({
  //     url: `category/${query3}`,
  //     headers: { Authorization: `Bearer ${storedToken}` },
  //   })
  //     .then((res) => {
  //       console.log(res);

  //       // setCustomerservices(res.data);
  //       console.log("Helo", res.data);
  //       setServiceType(res.data);
  //       var q4 = res.data[0].master.id;
  //       var value = `${q4}`;
  //       setQuery4(value);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [query4]);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <h3 style={{ color: "#555555", marginLeft: -250 }}>Category Head</h3>

        <NativeSelect
          value={query}
          onChange={(e) => handleChange(e)}
          inputProps={{
            name: "categoryhead",
            id: "categoryhead-native-helper",
          }}
        >
          {/* <option key="empty" value="">
            Choose Category Head
          </option> */}
          {categoryHead &&
            categoryHead.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
      <br />

      <FormControl className={classes.formControl}>
        <h3 style={{ color: "#555555", marginLeft: -290 }}>Category</h3>

        <NativeSelect
          value={query2}
          onChange={(e) => handleChange2(e)}
          inputProps={{
            name: "category",
            id: "category-native-helper",
          }}
        >
          {/* <option key="empty" value="">
            Choose Category
          </option> */}
          {category &&
            category.map((item) => (
              <option key={item.master.id} value={item.master.id}>
                {item.master.name}
              </option>
            ))}
        </NativeSelect>
      </FormControl>

      <br />

      <FormControl className={classes.formControl}>
        <h3 style={{ color: "#555555", marginLeft: -256 }}>Sub Category</h3>

        <NativeSelect
          value={query3}
          onChange={(e) => handleChange3(e)}
          inputProps={{
            name: "subcategory",
            id: "subcategory-native-helper",
          }}
        >
          {/* <option key="empty" value="">
            Choose SubCategory
          </option> */}
          {subcategory &&
            subcategory.map((item) => (
              <option key={item.master.id} value={item.master.id}>
                {item.master.name}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
      <br />

      <FormControl className={classes.formControl} >
        <h3 style={{ color: "#555555", marginLeft: -256 }}>Service type </h3>

        <NativeSelect
        autoComplete="off"
          value={query4}
          onChange={(e) => handleChange4(e)}
          inputProps={{
            name: "servicetype",
            id: "category-native-helper",
            }}

        >


          {questionnaireData &&
            questionnaireData.map((item) => (
              <option key={item.id} value={item.answer}>
                {item.answer}
              </option>
            ))}

        </NativeSelect>

      </FormControl>




      <br />

      <br />

      <div className={classes.formControl} style={{ marginLeft: -10 }}>

        {/* <Radio
          checked={selectedValue === "a"}
          onChange={handleChangeradio}
          value="a"
          name="radio-button-demo"
          inputProps={{ "aria-label": "A" }}
          color="primary"
          label="Interested"
        />
        <span style={{ marginRight: 20 }}>Interested</span>
        <Radio
          checked={selectedValue === "b"}
          onChange={handleChangeradio}
          value="b"
          name="radio-button-demo"
          inputProps={{ "aria-label": "B" }}
          color="primary"
          label="Booking"
        />
        <span style={{ marginRight: 20 }}>Booking</span>
        <Radio
          checked={selectedValue === "c"}
          onChange={handleChangeradio}
          value="c"
          name="radio-button-demo"
          inputProps={{ "aria-label": "C" }}
          color="primary"
          label="Not Interested"
        />
        <span style={{ marginRight: 20 }}>Not Interested</span> */}
        <Radio

          checked={selectedValue === "d"}
          onChange={handleChangeradio}
          value="d"
          name="radio-button-demo"
          inputProps={{ "aria-label": "D" }}
          color="primary"
          label="Service"
        />
        <span style={{ marginRight: 20 }}>New Service Booking</span>
      </div>

      <br />
      <br />

      <Button variant="contained" color="primary" style={{ marginLeft: -20 }}>
        {selectedValue !== "b" ? (
          <Link to={path} style={{ textDecoration: "none", color: "white" }}>
            Next
          </Link>
        ) : (
          {/* <a
             href="https://repairadda.com/"

            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            Next
          </a> */}
        )}
      </Button>
    </div>
  );
};

export default withRouter(Newserviceoutbond);
