import React, { useEffect, useState } from "react";
import API from "../utils/API";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport variant="contained" color="secondary" />
    </GridToolbarContainer>
  );
}

const BookingSource = () => {
  const history = useHistory();
  const [BookingSource, setBookingSource] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateFromButtonClick, setStartDateFromButtonClick] = useState();
  const [endDateFromButtonClick, setEndDateFromButtonClick] = useState();
  const [loading, setLoading] = useState(false);

  const options = { month: "long", day: "numeric", year: "numeric" };

  const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

  const columns = [
    
    { field: "id", headerName: "Id", width: 100 },
    {
      field: "tokenNumber",
      headerName: "Token Number",
      width: 200,
      valueFormatter: (value) => value.row?.tokenNumber,
    },
    {
        field: "source",
        headerName: "Source",
        width: 200,
        valueFormatter: (value) => value.row?.source?.name,
      },
    {
      field: "createdAt",
      headerName: "Creation Date",
      width: 180,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    {
        field: "createdPerson",
        headerName: "Created By",
        width: 150,
        valueFormatter: (value) => value.row?.createdPerson?.name,
      }
    //{ field: "remarks", headerName: "Remarks", width: 1000 },
  ];

  const handleClick = () => {
    setLoading(!loading);
    setStartDateFromButtonClick(startDate);
    setEndDateFromButtonClick(endDate);
    setBookingSource({});
  };

  useEffect(() => {
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    var today = new Date();

    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    let lastdate = daysInMonth(today.getMonth() + 1, today.getFullYear());

    if (month !== 10 && month !== 11 && month !== 12) {
      month = `0${month}`;
    }

    const start = `${year}-${month}-01`;
    const end = `${year}-${month}-${lastdate}`;

    setStartDate(start);
    setEndDate(end);
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }
    if (startDate !== "" && endDate !== "") {
      API({
        url: `/newserviceinbound?startDate=${startDateFromButtonClick}&endDate=${endDateFromButtonClick}`,
        headers: { Authorization: `Bearer ${storedToken}` },
      })
        .then((res) => {
          setLoading(false);
          console.log(res);
          console.log(res.data);
          setBookingSource(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [startDateFromButtonClick, endDateFromButtonClick, loading]);

  const mapToId = (BookingSource) =>
    BookingSource.map((row) => {
      const { tokenNo, ...rest } = row;
      return { id: tokenNo, ...rest };
      
    });

  return (
    <Box alignItems="center" style={{ width: "87%", marginLeft: "15rem" }}>
      <div>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <TextField
            onChange={(e) => setStartDate(e.target.value)}
            id="datetime-local"
            label="Start Date"
            type="date"
            value={startDate}
            style={{ transform: "translate(-15%,-20%)" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            onChange={(e) => setEndDate(e.target.value)}
            id="datetime-local"
            label="End Date"
            type="date"
            value={endDate}
            style={{ transform: "translate(8%,-20%)" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          <Button
            type="submit"
            style={{
              padding: "4px",
              margin: "5px",
              marginTop: "20px",
            }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Submit
          </Button>
        </form>

        <br />

        <div
          style={{
            height: 400,
            width: "90%",
            background: "transparent",
            color: "black",
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          {BookingSource.length > 0 && (
            <DataGrid
              rows={mapToId(BookingSource)}
              columns={columns}
              pageSize={10}
              sortingOrder={["asc", "desc"]}
              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
              components={{
              Toolbar: CustomToolbar,
              }}
            />
          )}

          {loading ? <CircularProgress style={{ marginTop: 50 }} /> : ""}
        </div>
      </div>
    </Box>
  );
};

export default BookingSource;
