import React, { useEffect, useState } from "react";
import "../../src/App.css";
import API from "../utils/API";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

const NotInterestedUsers = () => {
  const [issues, setIssue] = useState();
  const [issueId, setIssueId] = useState("3667");
  const [remarks, setRemarks] = useState("");
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { phonenumber } = location.state;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }
    API({
      url: `/categoryHead/21`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);

        // setCustomerservices(res.data);
        console.log(res.data);
        setIssue(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const createdBy= localStorage.getItem("userId");

  const formData = {
    issueTypeId: issueId,
    customerNumber: phonenumber,
    remarks: remarks,
    createdBy: createdBy,
  };

  function sendData(data) {
    const storedToken = localStorage.getItem("tokenStore");

    API({
      method: "POST",
      url: `/notinterested`,
      headers: {
        Authorization: `Bearer ${storedToken}`,
        ContentType: "application/json",
      },
      data: data,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          console.log(response);
          setOpen(true);
          return response;
        } else {
          console.log("Something happened wrong");
        }
      })
      .catch((err) => err);
  }

  const handleClick = (e) => {
    e.preventDefault();
    console.log(formData);
    sendData(formData);
  };

  return (
    <div className="not-container">
      <form className="issueform" onSubmit={(e) => e.preventDefault()}>
        <div className="issue-dropdown-box">
          <label className="issue">Issue:</label>

          <select
            className="issue-dropdown"
            value={issueId}
            onChange={(e) => setIssueId(e.target.value)}
          >
            {issues &&
              issues.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            {/* <option selected value="price">Price</option>
              <option value="timing">Timing</option>
              <option value="dnd">DND</option>
              <option value="not-now">Not Now</option>
              <option value="relocated">Relocated</option> */}
          </select>
        </div>

        <div className="remarksbox">
          <label className="remarks">Remarks:</label>

          <TextField
            id="outlined-basic"
            variant="outlined"
            multiline
            value={remarks}
            rows={4.5}
            style={{ marginLeft: 60 }}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </div>

        {/* <button type="submit" className="issue-submit">Submit</button> */}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={(e) => handleClick(e)}
        >
          Submit
        </Button>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Form submited successfully"}
        </DialogTitle>

        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Close
          </Button> */}
          <Button color="primary" autoFocus>
            <Link
              exact
              to="/"
              style={{ textDecoration: "none", color: "#008CBA" }}
            >
              Okay
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(NotInterestedUsers);
