import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import API from "../utils/API";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LockIcon from "@mui/icons-material/Lock";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Auth } from 'aws-amplify';
import ReactCodeInput from 'react-verification-code-input';


import Amplify from "aws-amplify";
import awsExports from "../aws-exports";
import { DialogContentText } from "@mui/material";
Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarauth: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  forms: {
    //width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(6),

  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitauth: {
    margin: theme.spacing(8, 0, 2),
  },
  submits: {
    margin: theme.spacing(1, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://repairadda.com/">
        RepairAdda
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function LoginCRM() {
  const classes = useStyles();

  const [userName, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const[sms_mfa,setSms_mfa]=useState(false);
  const history = useHistory();
  const [user, setUser] = useState(null); 
  const [code, setCode] = useState(""); 

  async function btnclick() {
    if (userName === "") {
      // <Alert severity="error">This is an error message!</Alert>;
      setOpen(true);
      <Dialog />;
    } else if (password === "") {
      // <Alert severity="error">This is an error message!</Alert>;
       setOpen(true);
    }

    if (userName !== "" && password !== "") {
      if (userName !== "customercare" || password !== "repairadda") {
       // setOpen(true);
      }

      console.warn("click" + userName, password);

      let item = { userName, password };
      // let result = await API({
      //   method: "post",
      //   url: "/signin",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //   },
      //   data: JSON.stringify(item),
      // });

        try {
            const u = await Auth.signIn(userName, password);
            console.log(u);
            if (u.challengeName=="SMS_MFA"){
              setSms_mfa(true)
              setUser(u)
            }
        } catch (error) {
            setOpen(true)
            console.error('error signing in', error);
        }
    


     // result = await result.data;

      // localStorage.setItem("user-info", JSON.stringify(result));
      // console.log(result);

      // localStorage.setItem(
      //   "tokenStore",
      //   JSON.stringify(result.token).slice(1, -1)
      // );

      // localStorage.setItem(
      //   "userId",
      //   JSON.stringify(result.userId)
      // );
      // console.log(localStorage.getItem("userId"));

      // // console.log(result.token);
      // history.push("/crmoutbound");
    }
  }

  async function verifyOtp() {
    
    Auth.confirmSignIn(user, code)
      .then(async signInUser => {
        if (signInUser.signInUserSession) {
              history.push("/crmoutbound");
        }
      }).catch((err) => {
        setOpen(true)
        console.log(err);
        //alert('please enter valid otp')
      });
  }

  async function resendOtp() {       
    btnclick()
    setShowMsg(true)
    setTimeout(() => { setShowMsg(false)}, 3000);
  }
  
  
  

   const redirectToPage=async()=>{
     await Auth.currentAuthenticatedUser().then(u=>{
      localStorage.setItem("tokenStore",u.signInUserSession.accessToken.jwtToken)
      history.push("/crmoutbound")
     })      
    }
  useEffect(() => {
    redirectToPage()
  }, []);

  const [open, setOpen] = React.useState(false);
  const [showMsg, setShowMsg] = React.useState(false);
  

  const handleClose = () => {
    setOpen(false);
  };
  
  console.log(sms_mfa)

  if(sms_mfa){
    return(
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          
          <Typography component="h1" variant="h4">
            Authentication
          </Typography>
          <Avatar className={classes.avatarauth}>
            <LockIcon />
          </Avatar>

          <Typography component="h1" variant="h6">
            Please enter the authentication code
          </Typography>
          <form
             className={classes.forms}
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >

            <ReactCodeInput onChange={(e) => setCode(e)} />;

              <Button
              type="submit"
              fullWidth
              variant="contained"
             // color="primary"
              className={classes.submitauth}
              onClick={verifyOtp}
            >
              Verify OTP
            </Button>

            
            <Button
              type="submit"
              fullWidth
              variant="contained"
             // color="#ff7961"
              className={classes.submits}
              onClick={resendOtp}
            >
              Resend OTP
            </Button>
            { showMsg && <Alert severity="success">OTP sent successfully</Alert>}
            
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Incorrect OTP"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Please enter valid OTP
                </DialogContentText>
      
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Retry
                </Button>
              </DialogActions>
            </Dialog>

            

          
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="text"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={btnclick}
            >
              Sign In
            </Button> */}
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>

    )
  }
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="text"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={btnclick}
            >
              Sign In
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Your username and/or password do not match "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Email or Password field is empty
          </DialogContentText>
      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default LoginCRM;
