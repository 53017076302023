import { Link, useLocation, withRouter } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import API from "../utils/API";
import { useHistory } from "react-router-dom";
import NativeSelect from "@mui/material/NativeSelect";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
  },
  select: {
    minHeight: 30,
    background: "#fafafa",
    border: "1px",
    borderBottom: "1px",
    outline: "1px",
    color: "#555555",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    height: "19px",
    lineHeight: "19.0016px",
    margin: "0px",
    padding: "6px 0px 7px",
    width: "366px",
  },
}));

const ActionLeadgeneration = (props) => {
  const classes = useStyles();
  // const [age, setAge] = React.useState("");
  const history = useHistory();

  const location = useLocation();

  console.log("State " + JSON.stringify(location.state));

  const { categoryHeadId, categoryId, subCategoryId, cm } =
    location.state !== undefined ? location.state : "";

  const [categoryHead, setcategoryHead] = React.useState();
  const [leadcategory, setLeadCategory] = React.useState("3659");
  const [selectedValue, setSelectedValue] = React.useState("yes");
  const [discount, setDiscount] = React.useState("");
  const [remarks, setRemarks] = React.useState("");
  //const [createdBy, setCreatedBy] = React.useState("");
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  const handleChangeradio = (event) => {
    setSelectedValue(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {

  // };

  const today = new Date();

  const todaydate = today.toISOString().split("T")[0];

  const [callbackdate, setCallbackDate] = React.useState(todaydate);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }

    API({
      url: `/categoryHead/19`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);

        // setCustomerservices(res.data);
        console.log(res.data);
        setcategoryHead(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 400) {
          history.push("/");
        }
      });
    // eslint-disable-next-line
  }, []);

  

  const initialFormData = {
    categoryHeadId: categoryHeadId,
    categoryId: categoryId,
    subCategoryId: subCategoryId,
    cm: cm,
  };

  console.log("Initial Form " + JSON.stringify(initialFormData));

  const createdBy= localStorage.getItem("userId");

  const update = (initialFormData) => {
    return {
      ...initialFormData,
      leadCategoryId: leadcategory,
      discountValue: discount,
      callbackDate: callbackdate,
      remarks: remarks,
      createdBy: createdBy,
    };
  };

  function sendLeadData(data) {
    const storedToken = localStorage.getItem("tokenStore");

    API({
      method: "POST",
      url: `/leadgeneration`,
      headers: {
        Authorization: `Bearer ${storedToken}`,
        ContentType: "application/json",
      },
      data: data,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          console.log(response);
          setOpen(true);
          return response;
        } else {
          console.log("Somthing happened wrong");
        }
      })
      .catch((err) => err);
  }

  const handleSubmit = (event) => {
    const updatedData = update(initialFormData);
    event.preventDefault();
    console.log("Up", updatedData);
    sendLeadData(updatedData);
  };

  return (
    <div>
      <FormControl className={classes.formControl} style={{ marginTop: -20 }}>
        <h3 style={{ color: "#555555", marginLeft: -257 }}>Lead Category</h3>

        <NativeSelect
          value={leadcategory}
          name="leadcategory"
          className={classes.select}
          onChange={(e) => setLeadCategory(e.target.value)}
        >
          {categoryHead &&
            categoryHead.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
        </NativeSelect>
        <br />
        <div
          className={classes.formControl}
          style={{ marginLeft: -228, display: "none" }}
        >
          <h3 className={classes.formControl} style={{ marginLeft: -50 }}>
            Discount
          </h3>
          Yes
          <Radio
            checked={selectedValue === "yes"}
            onChange={(e) => handleChangeradio(e)}
            value="yes"
            name="radio-button-demo"
            inputProps={{ "aria-label": "Yes" }}
            color="primary"
            label="Interested"
            labelPlacement="bottom"
            // className={classes.formControl}
          />
          No
          <Radio
            checked={selectedValue === "no"}
            onChange={(e) => handleChangeradio(e)}
            value="no"
            name="radio-button-demo"
            inputProps={{ "aria-label": "No" }}
            color="primary"
            label="Booking"
            labelPlacement="bottom"
            // className={classes.formControl}
          />
        </div>

        <TextField
          id="standard-textarea"
          label="Discount Amount"
          placeholder="Enter Amount"
          multiline
          className={classes.formControl}
          name="discount"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          style={{ marginLeft: -0.5, display: "none" }}
        />

        <br />

        <TextField
          id="date"
          className={classes.formControl}
          label="Callback Date"
          type="date"
          value={callbackdate}
          InputLabelProps={{
            shrink: true,
          }}
          name="date"
          onChange={(e) => setCallbackDate(e.target.value)}
          style={{ marginLeft: -0.5 }}
        />
        <br />
       {/*<br />
        <TextField
          id="standard-textarea"
          label="CreatedBy"
          minWidth="240"
          className={classes.formControl}
          name="createdBy"
          value={createdBy}
          onChange={(e) => setCreatedBy(e.target.value)}
          style={{ marginLeft: -0.5 }}
        />*

        <br />*/}
        <br />
        <TextField
          id="standard-textarea"
          label="Remarks"
          placeholder="Write Remarks"
          multiline
          rows={4}
          minWidth="240"
          className={classes.formControl}
          name="remarks"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          style={{ marginLeft: -0.5 }}
        />

        <br />
        <br />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ width: "50%", marginLeft: "25%" }}
        >
          <Link exact style={{ textDecoration: "none", color: "white" }}>
            Submit
          </Link>
        </Button>
      </FormControl>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Lead added successfully"}
        </DialogTitle>

        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Close
          </Button> */}
          <Button color="primary" autoFocus>
            <Link
              exact
              to="/"
              style={{ textDecoration: "none", color: "#008CBA" }}
            >
              Okay
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(ActionLeadgeneration);
