import React, { useState, useEffect } from "react";
import API from "../utils/API";
import APIOld from "../utils/APIOld";
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import './Newservice.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Typography } from '@material-ui/core';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0.7),
      width: "60ch",
     
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 30,
    },
  },
  
}));

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };



const Newserviceintrestedcustomer = (props) => {
  const classes = useStyles();
  // const [value, setValue] = React.useState("Controlled");

  const location = useLocation();

  console.log(location.state);

  const { query, query2, query3, query4, phonenumber } = location.state;
  const history = useHistory();

  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [address, setAddress] = useState();
  const [pincode, setPincode] = useState();
  const [locality, setLocality] = useState();
  const [landmark, setLandmark] = useState();
  const [state, setState] = useState();
  const [email, setEmail] = useState();
  const [country, setCountry] = useState("India");
  const [zipcode, setZipCode] = useState();
  const [time, setTime] = useState();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const today = new Date();

  const todaydate = today.toISOString().split("T")[0];
  const [callbackdate, setCallbackDate] = React.useState(todaydate);


  const [addressValue, setAddressValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const [open, setOpen] = React.useState(false);
  const [source, setSource] = React.useState();
  const handleClose = () => {
    setOpen(false);
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyCWgJBHzsmsODrrUNvLRb3DE9myUf_M-Qw&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(addressValue ? [addressValue] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (addressValue) {
          newOptions = [addressValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [addressValue, inputValue, fetch]);




  const handleChange = address => {
    setAddress(address)
  };

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required*').min(4,"min"),
    pincode: Yup.string().required('Pincode is required*'),
    //state: Yup.string().required('state is required*'),
    email: Yup.string().required('email is required*'),
    number: Yup.string().required('mobile number is required*'),
    address: Yup.string().required('address is required*'),
    callbackdate: Yup.string().required('Date is required*'),
    time: Yup.string().required('Time is required*'),
    landmark: Yup.string().required('landmark is required*'),
    locality: Yup.string().required('locality is required*')});    
    
    const {
      register,
      control,
      setValue,
      handleSubmit,
      formState: { errors }
    } = useForm({
      resolver: yupResolver(validationSchema)
    });

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) =>
        // console.log("Yipppe", results);
        // const fulladdress = [...results];
        // setZipCode(fulladdress[fulladdress.length - 1].long_name);
        getLatLng(results[0])
      )
      .then((latLng) => {
        setLatitude(latLng.lat);
        setLongitude(latLng.lng);
        console.log(latLng);
      })
      .catch((error) => console.error("Error", error));

    geocodeByAddress(address)
      .then((results) => {
        setAddress(address);
        console.log("Yipppe", results);
        const fulladdress = results.map((obj) => obj.address_components);
        const formatedaddress = results.map((obj) => obj.formatted_address);
        let values = fulladdress[0].map((item) => item.long_name);
        if (formatedaddress == "Sector 62, Noida, Uttar Pradesh, India") {
          console.log("ZIP CODE IS 201309");
          setZipCode("201309");
          setCountry(values[values.length - 1]);
          setState(values[values.length - 2]);
        } else {
          setZipCode(values[values.length - 1]);
          setCountry(values[values.length - 2]);
          setState(values[values.length - 3]);
        }
      })

      .catch((error) => console.error("Error", error));
  };

  const handleClick = (address) => {
    geocodeByAddress(address.description)
      .then((results) =>
        // console.log("Yipppe", results);
        // const fulladdress = [...results];
        // setZipCode(fulladdress[fulladdress.length - 1].long_name);
        getLatLng(results[0])
      )
      .then((latLng) => {
        setLatitude(latLng.lat);
        setLongitude(latLng.lng);
        console.log(latLng);
      })
      .catch((error) => console.error("Error", error));

    geocodeByAddress(address.description)
      .then((results) => {
        setAddress(address.description);
        console.log("Yipppe", results);
        const fulladdress = results.map((obj) => obj.address_components);
        const formatedaddress = results.map((obj) => obj.formatted_address);
        let values = fulladdress[0].map((item) => item.long_name);
        if (formatedaddress == "Sector 62, Noida, Uttar Pradesh, India") {
          console.log("ZIP CODE IS 201309");
          setZipCode("201309");
          setCountry(values[values.length - 1]);
          setState(values[values.length - 2]);
        } else {
          //setZipCode(values[values.length - 1]);
          setCountry(values[values.length - 1]);
          setState(values[values.length - 2]);
        }
      })

      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    setValue("number",phonenumber)
    if (!storedToken) {
      history.push("/");
    }

    API({
      url: `/customerdetail/${phonenumber}`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  



  const reverseString = (str) => {
    var replacedstring = str.replace("/-/g", "/");
    var splitstring = replacedstring.split("-");
    var reverseString = splitstring.reverse();
    var joinarray = reverseString.join("/");

    return joinarray;
  };

  const submitBtn = (addressdata) => {
    const storedToken = localStorage.getItem("tokenStore");
    let changeddate = reverseString(callbackdate);
    let formData = new FormData();
    formData.append("categoryHeadId.id", query);
    formData.append("category.id", query2);
    formData.append("subCategory.id", query3);
    formData.append("serviceType.id", query4);
    formData.append("name", addressdata.fullname);
    formData.append("email",addressdata.email);
    formData.append("formatted_address", address);
    formData.append("administrative_area_level_1", addressdata.state);
    formData.append("zipcode", addressdata.pincode);
    formData.append("country", country);
    formData.append("lat", latitude);
    formData.append("lng", longitude);
    formData.append("mobile", addressdata.number);
    formData.append("time", time);
  
     formData.append("message", query4+'(Backend)');
    // formData.append("pageNumber", 1);
     formData.append("availableDate", changeddate);
     formData.append("availableTime", time);
     formData.append("streetAddressLandmark", addressdata.landmark);
    // formData.append("mobile", contactno);

     formData.append("idForUpdate", 0);
    // formData.append("userId", userId);
    console.log(formData);
    //console.log("rajan",query4);
    APIOld({
      url: `/saveOrUpdateCustomerServiceRequest.html`,
      headers: { Authorization: `Bearer ${storedToken}`,
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    },
      method:'POST',
      data:formData
    })
      .then((res) => {
        console.log(res);
       // setSource(res.data);
        setOpen(true)

      })
      .catch((err) => {
        console.log(err);
      });

  };
 
  return (
    <>
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        style={{ marginTop: -20 }}
      >
        <h3 style={{ color: "#555555" }}>Customer Details</h3>
        <div>
       
          <br />
         
         
         
              <TextField
                
                value={name}
            onChange={(e) => setName(e.target.value)}
                id="fullname"
                name="fullname"
                label="Full Name"
                {...register('fullname')}
                error={errors.fullname ? true : false}
                placeholder= {errors.fullname?.message}

              />
              
              
          <br />
          <TextField
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            id="standard-textarea"
            label="Customer Number"
            placeholder="Enter number here"
            multiline
            type="number"
            name="customernumber"
            {...register('number')}
                error={errors.number ? true : false}
                placeholder= {errors.number?.message}
              />
              
          
          <br />

          <Autocomplete
      id="google-map-demo"
      // style={{ marginLeft: "0.5%" }}
      
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={addressValue}
      onChange={(addressdata, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setAddressValue(newValue);
        console.log(newValue);
        handleClick(newValue);
      }}
      onInputChange={(addressdata, newInputValue) => {
        setInputValue(newInputValue);
        console.log(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Customer Address" fullWidth
        {...register('address')}
                error={errors.address ? true : false}
                placeholder= {errors.address?.message}
         />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />

          
        
          <TextField
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            id="standard-textarea"
            label="Customer Pincode"
            placeholder="Enter Pincode here"
            multiline
            type="number"
            {...register('pincode')}
                error={errors.pincode ? true : false}
                placeholder= {errors.pincode?.message}
          />
          
          <br />
          <TextField
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
            id="standard-textarea"
            label="Customer Locality"
            placeholder="Enter locality here"
            multiline
            {...register('locality')}
                error={errors.locality ? true : false}
                placeholder= {errors.locality?.message}
          />
           <br/>
           <TextField
            value={landmark}
            onChange={(e) => setLandmark(e.target.value)}
            id="standard-textarea"
            label="Customer landmark"
            placeholder="Enter landmark here"
            multiline
            {...register('landmark')}
                error={errors.landmark ? true : false}
                placeholder= {errors.landmark?.message}
          />
          
          <br />
          {/* <TextField
            value={state}
            onChange={(e) => setState(e.target.value)}
            id="standard-textarea"
            label="Customer State"
            placeholder="Enter state here"
            multiline
            {...register('state')}
                error={errors.state ? true : false}
                placeholder= {errors.state?.message}
          /> */}
          
          
          <br />
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="standard-textarea"
            label="Customer Email"
            placeholder="Enter email here"
            multiline
            type="email"
            {...register('email')}
                error={errors.email ? true : false}
                placeholder= {errors.email?.message}
          />
           
          
          
          <br />
          
          <TextField 
          id="date"
          className={classes.formControl}
          label="Available Date"
          type="date"
          value={callbackdate}
          InputLabelProps={{
            shrink: true,
          }}
          name="date"
          {...register('callbackdate')}
                error={errors.callbackdate ? true : false}
                placeholder= {errors.callbackdate?.message}
          onChange={(e) => setCallbackDate(e.target.value)}
          style={{ marginLeft: -0.5 }}
          style={{ marginTop: '10px' }}
        />
<br />

{/* <h1>Select Time Slot</h1> */}
{/* <div class="select" style={{ marginLeft: "31%" }}>

   <select name="format" id="format"
    {...register('time')}
                error={errors.time ? true : false}
                placeholder= {errors.time?.message}
    onChange={(e) => setTime(e.target.value)}>
      <option selected disabled>Select Time</option>
      <option value="9AM - 12AM">9AM - 12AM</option>
      <option value="12PM -3PM">12PM -3PM</option>
      <option value="3PM -6PM">3PM -6PM</option>
      <option value="6PM - 9PM">6PM - 9PM</option>
      
   </select>
</div> */}

{/* Select Time Slot */}
<div>

<FormControl sx={{ m: 1, minWidth: 525 }}>
<InputLabel>Available Time</InputLabel>
  <Select name="format" id="format" style={{ textAlign: "left" }}
  label= "Available Time"
          {...register('time')}
                error={errors.time ? true : false}
                placeholder= {errors.time?.message}
          onChange={(e) => setTime(e.target.value)}>
          <MenuItem selected disabled>Select Time</MenuItem>
          <MenuItem value="9-12 Morning">9-12 Morning</MenuItem>
          <MenuItem value="12-3 Afternoon">12-3 Afternoon</MenuItem>
          <MenuItem value="3-6 Evening">3-6 Evening</MenuItem>
          <MenuItem value="6-9 Night">6-9 Night</MenuItem>
  </Select>
</FormControl>

</div>

{/* ////////////////////// */}

        </div>

        <br />

        


        <Button variant="contained" color="primary" onClick={handleSubmit(submitBtn)}>
          
          SAVE
        </Button>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle  id="alert-dialog-title">
          {"New Service Booking successfully"}
        </DialogTitle>

        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Close
          </Button> */}
          <Button color="primary" autoFocus>
            <Link
              exact
              to="/"
              style={{ textDecoration: "none", color: "#008CBA" }}
            >
              Okay
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
      <br/>
    </>
  );
};

export default withRouter(Newserviceintrestedcustomer);



