import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import API from "../utils/API";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Radio from "@mui/material/Radio";
import { DataGrid } from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
//import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // width: "30ch",
      margin: theme.spacing(1),
      flexGrow: 1,
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    minWidth: "6rem",
  },

  tableRow: {
    height: 30,
  },
  tableCell: {
    padding: "8px 17px 25px 17px",
  },
}));

const styles = {
  mobilenumber: {
    marginRight: 70,
    marginTop: -20,
    position: "relative",
  },
  mobileform: {
    marginBottom: -20,
    marginLeft: -230,
  },
  button: {
    minWidth: 150,
    transform: "translate(-20%,-42%)",
  },
  calltypebox: {
    display: "block",
    marginLeft: -260,
  },
  calltype: {
    minWidth: 160,
    // marginLeft:-35,
    // marginTop:20,
  },
  menuitem: {
    fontSize: 15,
    backgroundColor: "red",
  },
  customertable: {
    marginTop: -300,
    marginLeft: 850,
    marginBottom: 100,
  },
  infotable: {
    minWidth: "145%",
  },
};

function CrmInbound() {
  const [customerservices, setCustomerservices] = useState({});
  const [leadReport, setLeadReport] = useState({});
  //const [notContacted, setNotContacted] = useState({});
  //const [notInterested, setNotInterested] = useState({});
  const [interactionhistory, setInteractionhistory] = useState({});

  const [id, setId] = useState("");
  const [entered, setEntered] = useState(false);
  const [idFromButtonClick, setIdFromButtonClick] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const handleClick = () => {
    if (id === "") {
      alert("Please Enter Mobile Number ");
    } else {
      setIdFromButtonClick(id);
      setTimeout(() => {
        setEntered(true);
      }, 500);

      setCustomerservices({});
      //setNotContacted({});
      //setNotInterested({});
      setInteractionhistory({});
    }
  };

  const options = { month: "long", day: "numeric", year: "numeric" };

  const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

  ////////////// Booked Service Report //////////////////

  {
    /*  const customerColumns = [

    { field: "id", headerName: "Token Date", width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },


    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
      valueFormatter: (value) => value.row?.customerName,

    },
    {
      field: "customerNumber",
      headerName: "Customer Mobile",
      width: 200,
      valueFormatter: (value) => value.row?.customerNumber,
    },

    {
      field: "customerAddress",
      headerName: "Customer Address",
      width: 200,

    },

  ];*/
  }

  const customerservicesColumns = [
    // {
    //   field: "id",
    //   headerName: "Token Date",
    //   width: 200,
    //   valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    // },

    // {
    //   field: "tokenNumber",
    //   headerName: "Token Number",
    //   width: 200,
    //   valueFormatter: (value) => value.row?.tokenNumber,
    // },

    {
      field: "id",
      headerName: "ID",
      width: 100,
      // valueFormatter: (value) => value.row?.tokenNumber,

      //valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },

    {
      field: "tokenDate",
      headerName: "Token Date",
      width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },

    {
      field: "tokenNumber",
      headerName: "Token Number",
      width: 200,
      valueFormatter: (value) => value.row?.tokenNumber,
    },

    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
      valueFormatter: (value) => value.row?.customerName,
    },
    {
      field: "customerNumber",
      headerName: "Customer Mobile",
      width: 200,
      valueFormatter: (value) => value.row?.customerNumber,
    },
    {
      field: "goodsServiceName",
      headerName: "Service/Product",
      width: 200,
    },
    {
      field: "customerAddress",
      headerName: "Customer Address",
      width: 500,
    },
    {
      field: "leadSource",
      headerName: "Lead Source",
      width: 200,
    },
    {
      field: "salesPerson",
      headerName: "Sales Person",
      width: 200,
    },
    {
      field: "companyName",
      headerName: "Vendor Name",
      width: 200,
    },
    {
      field: "statusMarked",
      headerName: "Status Marked",
      width: 200,
    },
    {
      field: "statusMarkedDate",
      headerName: "Status Marked Date",
      width: 280,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
  ];

  ////////////// Lead Report //////////////////

  const leadColumns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "cHead",
      headerName: "Category Head",
      width: 200,
      valueFormatter: (value) => value.row?.cHead?.name,
    },
    {
      field: "cat",
      headerName: "Category",
      width: 200,
      valueFormatter: (value) => value.row?.cat?.name,
    },
    {
      field: "subCat",
      headerName: "SubCategory",
      width: 200,
      valueFormatter: (value) => value.row?.subCat?.name,
    },
    {
      field: "leadCategoryId",
      headerName: "Lead Type",
      width: 190,
      valueFormatter: (value) => value.row?.leadCategory?.name,
    },
    {
      field: "date",
      headerName: "Entry Date",
      width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },

    {
      field: "source",
      headerName: "Source",
      width: 140,
      valueFormatter: (value) => value.row?.source?.name,
    },

    // { field: "discountValue", headerName: "Discount Value", width: 160},
    {
      field: "callbackDate",
      headerName: "Callback Date",
      width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
      valueFormatter: (value) => value.row?.cm?.customerName,
    },
    {
      field: "customerNumber",
      headerName: "Customer Mobile",
      width: 200,
      valueFormatter: (value) => value.row?.cm?.customerNumber,
    },
    {
      field: "createdPerson",
      headerName: "Created By",
      width: 150,
      valueFormatter: (value) => value.row?.createdPerson?.name,
    },
    { field: "remarks", headerName: "Remarks", width: 300 },
  ];

  ////////// combine /////////////////

  const columns = [
    { field: "id", headerName: "Id", width: 100 },
    {
      field: "issueType",
      headerName: "Response",
      width: 200,
      valueFormatter: (value) => value.row?.issueType?.name,
    },
    { field: "customerNumber", headerName: "Customer Number", width: 250 },
    {
      field: "createdAt",
      headerName: "Creation Date",
      width: 180,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    {
      field: "createdPerson",
      headerName: "Created By",
      width: 150,
      valueFormatter: (value) => value.row?.createdPerson?.name,
    },
    { field: "remarks", headerName: "Remarks", width: 200 },
  ];

  //////////////////////////////////////////////

  var count = 0;

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }

    if (id !== "") {
      API({
        url: `customer/${id}`,
        headers: { Authorization: `Bearer ${storedToken}` },
      })
        .then((res) => {
          console.log(res);
          setCustomerservices(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [idFromButtonClick]);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }

    if (id !== "") {
      API({
        url: `interactionhistory/${id}`,
        headers: { Authorization: `Bearer ${storedToken}` },
      })
        .then((res) => {
          console.log(res);
          const combined = [].concat(
            res.data.notInterested,
            res.data.notContacted
          );
          console.log(combined);

          //setNotContacted(res.data.notContacted);
          //setNotInterested(res.data.notInterested);
          setInteractionhistory(combined);
          setLeadReport(res.data.leadReport);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [idFromButtonClick]);

  const reverse = (str) => {
    var splitString = str.split("-");

    var reverseArray = splitString.reverse();

    var joinArray = reverseArray.join("-");

    return joinArray;
  };

  // const mapToId = (customerservices) =>
  //   customerservices.map((row) => {
  //     const { tokenDate, ...rest } = row;
  //     return { id: tokenDate, ...rest };
  //   });

  const mapToId = (e) => {
    return e.tokenNumber;
  };

  const mapsToId = (interactionhistory) =>
    interactionhistory.map((row) => {
      const { issueId, ...rest } = row;
      return { id: issueId, ...rest };
    });

  return (
    <div>
      <h2 style={{ marginRight: 684, marginTop: -15, marginBottom: -5 }}>
        CRM INBOUND
      </h2>

      <br />

      {/* input number field and lead options */}

      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={10} style={{ marginLeft: 14 }}>
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              style={styles.mobileform}
              onSubmit={(e) => e.preventDefault()}
            >
              <TextField
                id="outlined-basic"
                label="Enter Mobile Number"
                variant="outlined"
                aria-describedby="my-helper-text"
                type="text"
                style={styles.mobilenumber}
                value={id}
                onChange={(e) => setId(e.target.value)}
              />

              {/* submit button */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleClick}
                style={styles.button}
              >
                Submit
              </Button>
            </form>
          </Grid>
          {entered ? (
            <Grid
              item
              xs={4}
              style={{ marginLeft: 230, display: "flex", flexWrap: "wrap" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.buttons}
                style={{ marginTop: "5%", marginLeft: 5, width: 200 }}
                size="medium"
              >
                <Link
                  to={{
                    pathname: "/inbound-leadgenerations",
                    state: {
                      phonenumber: id,
                    },
                  }}
                  key="inboundLeadgenerations"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Inbound Lead
                </Link>
              </Button>
              <br />
              <Button
                variant="contained"
                color="primary"
                className={classes.buttons}
                style={{ marginTop: "5%", marginLeft: 25, minWidth: 200 }}
                size="medium"
              >
                <Link
                  to={{
                    pathname: "/inbound-source",
                    state: {
                      phonenumber: id,
                    },
                  }}
                  key="inboundSource"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  New Service
                </Link>
              </Button>
              <br />
              <Button
                variant="contained"
                color="primary"
                className={classes.buttons}
                style={{ marginTop: "5%", marginLeft: 5, width: 200 }}
                size="medium"
              >
                <Link
                  to={{
                    pathname: "/inbound-complaint",
                    state: {
                      phonenumber: id,
                    },
                  }}
                  key="inboundComplaint"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Complaint
                </Link>
              </Button>
              <br />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>

      {/* details info table */}

      {customerservices.length > 0 && (
        <Grid
          container
          style={{
            width: 1200,
            marginTop: 90,
            marginLeft: -50,
            marginBottom: -150,
          }}
        >
          <Grid item xs={5}>
            <TableContainer component={Paper} style={styles.customertable}>
              <Table className={classes.table} aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      {/* <center> */}
                      <b>Customer Number: </b>{" "}
                      {customerservices[0].customerNumber}
                      {/* </center> */}
                    </TableCell>
                    <TableCell style={{ transform: "translateX(-5%)" }}>
                      {" "}
                      {/* <center> */}
                      <b>Customer Name: </b> {customerservices[0].customerName}
                      {/* </center> */}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      {" "}
                      {/* <center> */}
                      <b>Customer Address: </b>{" "}
                      {customerservices[0].customerAddress}
                      {/* </center> */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                      {" "}
                      <b>Last Service Date: </b>
                      {reverse(customerservices[0].tokenDate.split("T")[0])}
                    </TableCell> */}
                    {/* <TableCell style={{ transform: "translateX(-5%)" }}> */}
                    <TableCell>
                      <b>Total Services: </b> {customerservices.length} <br />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}

      {/* <Box alignItems="center" style={{  width: "87%", marginLeft: "15rem", marginTop: "5rem"  }}>

      <Table>
      {idFromButtonClick !=="" &&
      <Typography
         variant="h6"
          color="black">
            Booked Services
         </Typography> }

       <div
       style={{
         height: 450,
         width: "100%",
         background: "white",
         color: "white",
         marginTop: 20,
         marginLeft: 0,
       }}
     >
       {customerservices.length > 0 && (
         <DataGrid

           rows={mapToId(customerservices)}
           columns={customerColumns}
           pageSize={10}
           sortingOrder={["desc", "asc"]}
           sortModel={[
             {
               field: "id",
               sort: "desc",
             },
           ]}
         />
       )}

     </div>
     </Table>


     </Box>*/}

      <Box
        alignItems="center"
        style={{ width: "80%", marginLeft: "16rem", marginTop: "5rem" }}
      >
        <Table>
          {idFromButtonClick !== "" && (
            <h2 style={{ marginRight: "84%", color: "black" }}>
              Booked Services
            </h2>
          )}

          <div
            style={{
              height: 450,
              width: "100%",
              background: "white",
              color: "white",
              marginTop: 20,
              marginLeft: 0,
            }}
          >
            {customerservices.length > 0 && (
              <DataGrid
                getRowId={mapToId}
                rows={customerservices}
                columns={customerservicesColumns}
                pageSize={10}
                sortingOrder={["desc", "asc"]}
                sortModel={[
                  {
                    field: "id",
                    sort: "desc",
                  },
                ]}
              />
            )}
          </div>
        </Table>
      </Box>

      {/* view services table */}

      {/* <TableContainer
        component={Paper}
        style={{ width: "87%", marginLeft: "15rem", marginTop: 70 }}
      >
        <Table
          // className={classes.table}
          // size="large"
          style={styles.infotable}
          aria-label="a dense table"
        >
          {customerservices.length > 0 && (
            <TableHead>
            <h4>BOOKED SERVICES</h4>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell} align="right">
                  <b>ID</b>
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.tableCell}
                  style={{ width: 150, transform: "translateY(23%)" }}
                >
                  <b>Token Date</b>
                  <p>
                    <b>(dd-mm-yy)</b>
                  </p>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <b>Token Number</b>
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <b>Customer Name</b>
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <b>Customer Number</b>
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.tableCell}
                  style={{ textAlign: "left" }}
                >
                  <b>Service/Product</b>
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.tableCell}
                  style={{ textAlign: "center" }}
                >
                  <b>Customer Address</b>
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <b>Lead Source</b>
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <b>Sales Person</b>
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <b>Vendor Name</b>
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <b>Status Marked</b>
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.tableCell}
                  style={{ transform: "translateY(23%)" }}
                >
                  <b>Status Marked Date</b>
                  <p style={{ transform: "translateX(-20%)" }}>
                    <b>(dd-mm-yy)</b>
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {customerservices.length > 0 &&
              customerservices.map((y) => (
                <TableRow key={count}>
                  <TableCell align="right">{++count}</TableCell>
                  <TableCell align="right">
                    {reverse(y.tokenDate.split("T")[0])}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "left" }}>
                    {y.tokenNumber}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="right"
                    style={{ textAlign: "center" }}
                  >
                    {y.customerName}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "center" }}>
                    {y.customerNumber}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "left" }}>
                    {y.goodsServiceName}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "center" }}>
                    {y.customerAddress}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "center" }}>
                    {y.leadSource}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "center" }}>
                    {y.salesPerson}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "center" }}>
                    {y.companyName}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "center" }}>
                    {y.statusMarked}
                  </TableCell>
                  <TableCell align="right" style={{ textAlign: "center" }}>
                    {reverse(y.statusMarkedDate)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>*/}

      <Box
        alignItems="center"
        style={{ width: "80%", marginLeft: "15.5rem", marginTop: "5rem" }}
      >
        <Table>
          {idFromButtonClick !== "" && (
            <h2 style={{ marginRight: "54rem", color: "black" }}>
              Interaction History
            </h2>
          )}

          <div
            style={{
              height: 400,
              //idFromButtonClickth: "90%",
              background: "white",
              color: "white",
              marginTop: 20,
              marginLeft: 10,
            }}
          >
            {interactionhistory.length > 0 && (
              <DataGrid
                rows={mapsToId(interactionhistory)}
                columns={columns}
                pageSize={10}
                sortingOrder={["asc", "desc"]}
                sortModel={[
                  {
                    field: "createdAt",
                    sort: "desc",
                  },
                ]}
              />
            )}
          </div>
        </Table>
      </Box>

      <Box
        alignItems="center"
        style={{ width: "80%", marginLeft: "16rem", marginTop: "5rem" }}
      >
        <Table>
          {idFromButtonClick !== "" && (
            <h2 style={{ marginRight: "88%", color: "black" }}>Lead Report</h2>
          )}

          <div
            style={{
              height: 450,
              width: "100%",
              background: "white",
              color: "white",
              marginTop: 20,
              marginLeft: 0,
            }}
          >
            {leadReport.length > 0 && (
              <DataGrid
                rows={leadReport}
                columns={leadColumns}
                pageSize={10}
                sortingOrder={["desc", "asc"]}
                sortModel={[
                  {
                    field: "id",
                    sort: "desc",
                  },
                ]}
              />
            )}
          </div>
        </Table>
      </Box>
    </div>
  );
}
export default CrmInbound;
