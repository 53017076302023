import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import API from "../utils/API";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';

function CustomToolbar() {
  return (
    <GridToolbarContainer  >
      <GridToolbarExport variant="contained" color="secondary"/>
    </GridToolbarContainer>
  );
}

function XpressCheckout() {
  const history = useHistory();
  
  const [listView, setXpressCheckoutDate] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [startDateFromButtonClick, setStartDateFromButtonClick] = useState();

  const [endDateFromButtonClick, setEndDateFromButtonClick] = useState();

  const [loading, setLoading] = useState(false);

  const options = { month: "long", day: "numeric", year: "numeric" };

  const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 170,
      valueFormatter: ({ value }) => capitalize(value),
    },
    { field: "mobileNumber", headerName: "Mobile", width: 170 },
    { field: "addressToVisit", headerName: "Address", width: 470 },
    {
      field: "insertedDateTime",
      headerName: "Insert Date",
      width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    { field: "email", headerName: "Email", width: 270 },
  ];

  const handleClick = () => {
    setLoading(!loading);
    setStartDateFromButtonClick(startDate);
    setEndDateFromButtonClick(endDate);
    setXpressCheckoutDate({});
  };

  useEffect(() => {
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    var today = new Date();

    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    let lastdate = daysInMonth(today.getMonth() + 1, today.getFullYear());
    if (month !== 10 && month !== 11 && month !== 12) {
      month = `0${month}`;
    }
    console.log(month);
    console.log(year);
    console.log(lastdate);
    const start = `${year}-${month}-01`;
    const end = `${year}-${month}-${lastdate}`;
    console.log(start);
    console.log(end);

    setStartDate(start);
    setEndDate(end);
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }

    if (startDate !== "" && endDate !== "") {
      API({
        url: `/xpressCheckoutByDate?startDate=${startDate}&endDate=${endDate}`,
        headers: { Authorization: `Bearer ${storedToken}` },
      })
        .then((res) => {
          setLoading(false);
          console.log(res);

          setXpressCheckoutDate(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [startDateFromButtonClick, endDateFromButtonClick, loading]);
  return (
    <Box alignItems="center" style={{ width: "87%", marginLeft: "15rem" }}>
      <div>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <TextField
            onChange={(e) => setStartDate(e.target.value)}
            id="date"
            label="Start Date"
            type="date"
            style={{ transform: "translate(-15%,-20%)" }}
            InputLabelProps={{ shrink: true }}
            value={startDate}
          />

          <TextField
            id="date"
            label="End Date"
            type="date"
            style={{ transform: "translate(8%,-20%)" }}
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <br />

          <Button
            type="submit"
            style={{
              padding: "4px",
              margin: "5px",
              marginTop: "20px",
            }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Submit
          </Button>
        </form>
        <br />

        <div
          style={{
            height: 400,
            width: "85%",
            background: "transparent",
            color: "black",
            marginTop: 20,
            marginLeft: 80,
          }}
        >
          {listView.length > 0 && (
            <DataGrid
              rows={listView}
              columns={columns}
              pageSize={10}
              sortingOrder={["desc", "asc"]}
              sortModel={[
                {
                  field: "insertedDateTime",
                  sort: "desc",
                },
              ]}
              components={{
              Toolbar: CustomToolbar,
              }}
            />
          )}

          {loading ? <CircularProgress style={{ marginTop: 50 }} /> : ""}
        </div>
      </div>
    </Box>
  );
}

export default XpressCheckout;

// import React from 'react'

// function XpressCheckout() {
//   return (
//     <>
//       <h1>XpressCheckout</h1>
//     </>
//   )
// }
// export default XpressCheckout;