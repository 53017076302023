import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FlightIcon from "@mui/icons-material/Flight";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      //display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const location = useLocation();
  var pathname = location.pathname;
  const [currentlocation, setCurrentLocation] = useState(pathname);
  const [currentpath, setCurrentPath] = useState("");
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // switch(pathname){

  //   case "/users":
  //     currentpath="Users";
  //     break;
  //   case "/expressbooking":
  //     currentpath="Express Booking";
  //     break;
  //   case "/vendorpayment":
  //     currentpath="Vendor Payments";
  //     break;
  //   case "/vendorbalance":
  //     currentpath="Vendor Balance";
  //     break;
  //   case "/notinterested":
  //     currentpath="Not Interested";
  //     break;
  //   case "/notcontacted":
  //     currentpath="Not Contacted";
  //     break;
  //   case "/leadreport":
  //     currentpath="Lead Report";
  //     break;
  //   default :
  //     break;

  // }

  useEffect(() => {
    setCurrentLocation(pathname);
  }, [pathname]);

  useEffect(() => {
    switch (currentlocation) {
      case "/crm-outbound":
        setCurrentPath("CRM");
        break;
      case "/users":
        setCurrentPath("Users");
        break;
      case "/service-bookings":
        setCurrentPath("Service Bookings");
        break;
      case "/express-booking":
        setCurrentPath("Express Booking");
        break;
      case "/vendor-payment":
        setCurrentPath("Vendor Payments");
        break;
      case "/vendor-balance":
        setCurrentPath("Vendor Balance");
        break;
      case "/notinterested":
        setCurrentPath("Not Interested");
        break;
      case "/notcontacted":
        setCurrentPath("Not Contacted");
        break;
      case "/complaints":
        setCurrentPath("Complaints");
        break;
      case "/booking-source":
        setCurrentPath("BookingSource");
        break;
      case "/lead-report":
        setCurrentPath("Lead Report");
        break;
      case "/outbound":
        setCurrentPath("OutBound");
        break;
      case "/crm-inbound":
        setCurrentPath("InBound");
        break;
      case "/inbound-leadgenerations":
        setCurrentPath("Inbound Lead");
        break;
      case "/inbound-source":
        setCurrentPath("New Source");
        break;
      case "/inbound-complaint":
        setCurrentPath("Complaint");
        break;
      case "/leadgenerations":
        setCurrentPath("Lead Generation");
        break;
      case "/notcontacted-users":
        setCurrentPath("Not Contacted");
        break;
      case "/notinterested-users":
        setCurrentPath("Not Interested");
        break;
      case "/amc-report":
        setCurrentPath("AMC Report");
        break;
      case "/wallet-report":
        setCurrentPath("Wallet Report")  
        break;
      case "/technician-collection":
        setCurrentPath(" Technician  Collections");
        break;  
      case "/technician-report":
        setCurrentPath("Technician Report") 
        break; 
      case "/":
        setCurrentPath("");
        break;
      case "/":
        setCurrentPath("");
        break;
      default:
        break;
    }
  }, [currentlocation]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />

      <List>
        {["CRM"].map((text, index) => (
          <Link
            exact="true"
            to="/crm-outbound"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="crm">
              <ListItemIcon>
                {index === 0 ? <DashboardIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="crm" />
            </ListItem>
          </Link>
        ))}
        {["Service Bookings"].map((text, index) => (
          <Link
            exact="true"
            to="/service-bookings"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="servicebookings">
              <ListItemIcon>{index === 0 ? <GroupIcon /> : ""}</ListItemIcon>
              <ListItemText primary={text} key="servicebookings" />
            </ListItem>
          </Link>
        ))}
        {["Users"].map((text, index) => (
          <Link
            exact="true"
            to="/users"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="users">
              <ListItemIcon>{index === 0 ? <GroupIcon /> : ""}</ListItemIcon>
              <ListItemText primary={text} key="users" />
            </ListItem>
          </Link>
        ))}
        {["Express Booking"].map((text, index) => (
          <Link
            exact="true"
            to="/express-booking"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="express booking">
              <ListItemIcon>{index === 0 ? <FlightIcon /> : ""}</ListItemIcon>
              <ListItemText primary={text} key="express booking" />
            </ListItem>
          </Link>
        ))}

        {["Lead Report"].map((text, index) => (
          <Link
            exact="true"
            to="/lead-report"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="lead report">
              <ListItemIcon>
                {index === 0 ? <EventAvailableIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="lead report" />
            </ListItem>
          </Link>
        ))}

        {["AMC Report"].map((text, index) => (
          <Link
            exact="true"
            to="/amc-report"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="amcreport">
              <ListItemIcon>
                {index === 0 ? <EventRepeatIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="amcreport" />
            </ListItem>
          </Link>
        ))}

        {["Not Interested"].map((text, index) => (
          <Link
            exact="true"
            to="/notinterested"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="not interested">
              <ListItemIcon>
                {index === 0 ? <NotInterestedIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="not interested" />
            </ListItem>
          </Link>
        ))}

        {["Not Contacted"].map((text, index) => (
          <Link
            exact="true"
            to="/notcontacted"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="not contacted">
              <ListItemIcon>
                {index === 0 ? <PhoneMissedIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="not contacted" />
            </ListItem>
          </Link>
        ))}

        {["Booking Source"].map((text, index) => (
          <Link
            exact="true"
            to="/booking-source"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="bookingSource">
              <ListItemIcon>
                {index === 0 ? <LibraryBooksIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="bookingSource" />
            </ListItem>
          </Link>
        ))}

        {["Complaints"].map((text, index) => (
          <Link
            exact="true"
            to="/complaints"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="complaints">
              <ListItemIcon>
                {index === 0 ? <ErrorOutlineIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="complaints" />
            </ListItem>
          </Link>
        ))}

        {["Vendor Payments"].map((text, index) => (
          <Link
            exact="true"
            to="/vendor-payment"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="vendor payments">
              <ListItemIcon>{index === 0 ? <PaymentIcon /> : ""}</ListItemIcon>
              <ListItemText primary={text} key="vendor payments" />
            </ListItem>
          </Link>
        ))}

        
      {["Wallet Report"].map((text, index) => (
          <Link
            exact="true"
            to="/wallet-report"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="walletreport">
              <ListItemIcon>
                {index === 0 ? <EventRepeatIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="walletreport" />
            </ListItem>
          </Link>
        ))}

        {["Vendor Balance"].map((text, index) => (
          <Link
            exact="true"
            to="/vendor-balance"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="vendor balance">
              <ListItemIcon>
                {index === 0 ? <AccountBalanceWalletIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="vendor balance" />
            </ListItem>
          </Link>
        ))}
        
        {[" Technician Collection"].map((text, index) => (
          <Link
            exact="true"
            to="/technician-collection"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key=" Technician Collection">
              <ListItemIcon>{index === 0 ? <PaymentIcon /> : ""}</ListItemIcon>
              <ListItemText primary={text} key=" Technician Collection" />
            </ListItem>
          </Link>
        ))}

      {["Technician Report"].map((text, index) => (
          <Link
            exact="true"
            to="/technician-report"
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
            key={text}
          >
            <ListItem button key="Technician Report">
              <ListItemIcon>{index === 0 ? <PaymentIcon /> : ""}</ListItemIcon>
              <ListItemText primary={text} key="Technician Report" />
            </ListItem>
          </Link>
        ))}

        {/* {["Lead Report"].map((text, index) => (
                <Link
                  exact="true"
                  to="/leadreport"
                  style={{
                    textDecoration: "none",
                    color: "#008CBA",
                    fontWeight: "500",
                  }}
                  key={text}
                >
                  <ListItem button key="lead report">
                    <ListItemIcon>
                      {index === 0 ? <ReportIcon /> : ""}
                    </ListItemIcon>
                    <ListItemText primary={text} key="lead report" />
                  </ListItem>
                </Link>
              ))} */}
      </List>

      <Divider />

      <List>
        {["Logout"].map((text, index) => (
          <Link
            exact="true"
            to="/logout"
            key={index}
            style={{
              textDecoration: "none",
              color: "#008CBA",
              fontWeight: "500",
            }}
          >
            <ListItem button key="logout">
              <ListItemIcon>
                {index === 0 ? <ExitToAppIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} key="logout" />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            RepairAdda Backend - {currentpath}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="contained"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xlDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {/* <CrmOutbound /> */}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withRouter(ResponsiveDrawer);
