import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import API from "../utils/API";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport variant="contained" color="secondary" />
    </GridToolbarContainer>
  );
}

const AMCReport = () => {
  const history = useHistory();

  const [amc, setAmc] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [startDateFromButtonClick, setStartDateFromButtonClick] = useState();

  const [endDateFromButtonClick, setEndDateFromButtonClick] = useState();

  const [loading, setLoading] = useState(false);

  const options = { month: "long", day: "numeric", year: "numeric" };

  const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },

    {
      field: "amcStartDate",
      headerName: "Start Date",
      width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    {
      field: "amcExpireDate",
      headerName: "Expiry Date",
      width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    { field: "name", headerName: "Name", width: 200 },
    { field: "contactNo", headerName: "Phone", width: 200 },
    { field: "amcPeriod", headerName: "Period", width: 150 },
    {
      field: "subCategory",
      headerName: "Product",
      width: 220,
      valueFormatter: (value) => value.row?.subCategory?.name,
    },
    {
      field: "amcPackageId",
      headerName: "AMC Type",
      width: 260,
      valueFormatter: (value) => value.row?.amcPackageId?.name,
    },

    { field: "address", headerName: "Address", width: 600 },
  ];

  // const handleClick = () => {
  //   setLoading(!loading);
  //   setStartDateFromButtonClick(startDate);
  //   setEndDateFromButtonClick(endDate);
  //   setAmc({});
  // };

  // useEffect(() => {
  //   function daysInMonth(month, year) {
  //     return new Date(year, month, 0).getDate();
  //   }

  //   var today = new Date();

  //   let month = today.getMonth() + 1;
  //   let year = today.getFullYear();

  //   let lastdate = daysInMonth(today.getMonth() + 1, today.getFullYear());
  //   if (month !== 10 && month !== 11 && month !== 12) {
  //     month = `0${month}`;
  //   }
  //   console.log(month);
  //   console.log(year);
  //   console.log(lastdate);
  //   const start = `${year}-${month}-01`;
  //   const end = `${year}-${month}-${lastdate}`;
  //   console.log(start);
  //   console.log(end);

  //   setStartDate(start);
  //   setEndDate(end);
  // }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }

    API({
      url: `/amc-details`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        // setCustomerservices(res.data);
        console.log(res.data);
        setAmc(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line
  }, []);

  return (
    <Box alignItems="center" style={{ width: "80%", marginLeft: "16rem" }}>
      <div>
        {/* <form noValidate onSubmit={(e) => e.preventDefault()}>
          <TextField
            onChange={(e) => setStartDate(e.target.value)}
            id="date"
            label="Start Date"
            type="date"
            style={{ transform: "translate(-15%,-20%)" }}
            InputLabelProps={{ shrink: true }}
            value={startDate}
          />

          <TextField
            id="date"
            label="End Date"
            type="date"
            style={{ transform: "translate(8%,-20%)" }}
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <br />

          <Button
            type="submit"
            style={{
              padding: "4px",
              margin: "5px",
              marginTop: "20px",
            }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Submit
          </Button>
        </form> */}
        <br />

        <div
          style={{
            height: 460,
            width: "100%",
            background: "transparent",
            color: "black",
            marginTop: 10,
            marginLeft: 0,
          }}
        >
          {amc.length > 0 && (
            <DataGrid
              rows={amc}
              columns={columns}
              pageSize={10}
              sortingOrder={["desc", "asc"]}
              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          )}

          {loading ? <CircularProgress style={{ marginTop: 50 }} /> : ""}
        </div>
      </div>
    </Box>
  );
};

export default AMCReport;
