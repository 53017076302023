import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();
  Auth.signOut();
  localStorage.removeItem("tokenStore");
  history.push("/");
  return <div>Logout page</div>;
};

export default Logout;
