import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import API from "../utils/API";
import "../../src/App.css";
import { useHistory } from "react-router-dom";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport variant="contained" color="secondary" />
    </GridToolbarContainer>
  );
}

function ServiceBookings(props) {
  const history = useHistory();

  const [listView, setListView] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [startDateFromButtonClick, setStartDateFromButtonClick] = useState();

  const [endDateFromButtonClick, setEndDateFromButtonClick] = useState();

  const [loading, setLoading] = useState(false);

  const options = { month: "short", day: "numeric", year: "numeric" };
  const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "tokenNumber", headerName: "Token No", width: 150 },
    {
      field: "tokenDate",
      headerName: "Token Date",
      width: 150,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    { field: "customerName", headerName: "Customer Name", width: 250 },
    { field: "customerNumber", headerName: "Customer Mobile No", width: 250 },
    { field: "loyaltyAmount", headerName: "Loyalty Amount", width: 180 },
    { field: "customerAddress", headerName: "Customer Address", width: 550 },

    {
      field: "subCategory",
      headerName: "Service Category",
      width: 200,
      valueFormatter: (value) => value.row?.subCategory?.name,
    },
    { field: "statusMarked", headerName: "Service Status", width: 250 },
    {
      field: "statusMarkedDate",
      headerName: "Status Date",
      width: 200,
      valueFormatter: ({ value }) => dateFormatter.format(new Date(value)),
    },
    /* {
      field: "subCategory",
      headerName: "remark",
      width: 200,
      valueFormatter: (value) => value.row?.subCategory?.remark,
    },
    */
  ];

  const handleClick = () => {
    setLoading(!loading);
    setStartDateFromButtonClick(startDate);
    setEndDateFromButtonClick(endDate);
    setListView({});
  };
  //  const calculateWalletBalance=(data)=>{
  //    let customerWalletList={};
  //     for(let i in data){
  //       customerWalletList=data[i].customerWalletModels;

  //       for(let j in data[i].customerWalletModels){
  //         console.log(data[i].customerWalletModels[j].credit)
  //         console.log(data[i].customerWalletModels[j].debit)
  //         console.log(data[i].customerWalletModels[j].totalBalance)

  // if(data[i].customerWalletModels[j].credit <20){
  //   console.log("null amount")
  // }
  //       }
  //     }

  // }
  useEffect(() => {
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    var today = new Date();

    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    let lastdate = daysInMonth(today.getMonth() + 1, today.getFullYear());
    if (month !== 10 && month !== 11 && month !== 12) {
      month = `0${month}`;
    }
    // console.log(month);
    // console.log(year);
    // console.log(lastdate);
    // Commented by Urja
    const start = `${year}-${month}-01`;
    const end = `${year}-${month}-${lastdate}`;
    //const start = `01/${month}/${year}`;
    //const end = `${lastdate}/${month}/${year}`;
    console.log(start);
    console.log(end);

    setStartDate(start);
    setEndDate(end);
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }
// customer-wallet-details
    if (startDate !== "" && endDate !== "") {
      API({
        url: `/customer-booking-details?startDate=${startDateFromButtonClick}&endDate=${endDateFromButtonClick}`,
        headers: { Authorization: `Bearer ${storedToken}` },
      })
        .then((res) => {
          setLoading(false);
          // calculateWalletBalance(res.data)

          setListView(res.data);
          console.log(res.data);
        })

        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line
  }, [startDateFromButtonClick, endDateFromButtonClick, loading]);

  return (
    <Box alignItems="center" style={{ width: "87%", marginLeft: "15rem" }}>
      <div>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <TextField
            onChange={(e) => setStartDate(e.target.value)}
            id="date"
            label="Start Date"
            type="date"
            style={{ transform: "translate(-15%,-20%)" }}
            InputLabelProps={{ shrink: true }}
            value={startDate}
          />

          <TextField
            id="date"
            label="End Date"
            type="date"
            style={{ transform: "translate(8%,-20%)" }}
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <br />

          <Button
            type="submit"
            style={{
              padding: "4px",
              margin: "5px",
              marginTop: "20px",
            }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Submit
          </Button>
        </form>
        <br />

        <div
          style={{
            height: 400,
            width: "90%",
            background: "transparent",
            color: "black",
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          {listView.length > 0 && (
            <DataGrid
              rows={listView}
              columns={columns}
              pageSize={10}
              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          )}

          {loading ? <CircularProgress style={{ marginTop: 50 }} /> : ""}
        </div>
      </div>
    </Box>
  );
}

export default ServiceBookings;

// import React from 'react'

// function User() {
//   return (
//     <>
//       <h1>User</h1>
//     </>
//   )
// }
// export default User;
