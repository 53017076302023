import { Link, useLocation, withRouter } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import API from "../utils/API";
import { useHistory } from "react-router-dom";
import NativeSelect from "@mui/material/NativeSelect";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 30,
  },
  select: {
    minHeight: 30,
    background: "#fafafa",
    border: "1px",
    borderBottom: "1px",
    outline: "1px",
    color: "#555555",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    height: "19px",
    lineHeight: "19.0016px",
    margin: "0px",
    padding: "6px 0px 7px",
    width: "366px",
  },
}));

const InboundSource = (props) => {
  const classes = useStyles();
  // const [age, setAge] = React.useState("");
  const history = useHistory();

  const location = useLocation();

 

  
  const [token, setToken] = React.useState("");
  const [source, setSource] = React.useState();
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  const [query, setQuery] = React.useState("3680");


  const handleChange = (event) => {
    var value = event.target.value;

    setQuery(value);

    console.log(value);

    console.log("query is ", query);
  };


  const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {

  // };



  const handleClose = () => {
    setOpen(false);
  };


  ////// Source /////////



  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }
    API({
      url: `/categoryHead/23`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);

        // setCustomerservices(res.data);
        console.log(res.data);
        setSource(res.data);
      
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400 || err.response.status === 401) {
          history.push("/");
        }
      });
    // eslint-disable-next-line
  }, []);




  const createdBy= localStorage.getItem("userId");
  const inboundData = {
    tokenNumber:token,
    sourceId: query,
    createdBy:createdBy,
  };


  function sendLeadData(data) {
    const storedToken = localStorage.getItem("tokenStore");

    API({
      method: "POST",
      url: `/newserviceinbound`,
      headers: {
        Authorization: `Bearer ${storedToken}`,
        ContentType: "application/json",
      },
      data: inboundData,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          console.log(response);
          setOpen(true);
          return response;
        } else {
          console.log("Somthing happened wrong");
        }
      })
      .catch((err) => err);
  }

  var x;

  const handleSubmit = (event) => {
   
    event.preventDefault();
    console.log("Up", inboundData);
    if (token == "") {
      alert("Please Enter Token ");
    } 
    if (token.toString().length<12) {
      alert("Please input exactly 12 numbers!");
      return true;
    }
    else
    {
    sendLeadData(inboundData);
    }
  };

  return (
    <div className="not-container" style={{ marginTop: "2%" }}>
      <FormControl className={classes.formControl} style={{ marginTop: 15 }}>
        
       
        <br />
        <TextField
          id="standard-textarea"
          className={classes.formControl}
          label="Token Number"
          
          value={token}
          name="token"
          onChange={(e) => setToken(e.target.value)}
          style={{ marginLeft: -0.5 }}
        />
        <br />

       
      <FormControl className={classes.formControl}>
        <h3 style={{ color: "#555555", marginLeft: -140 }}>Source</h3>

        <NativeSelect
          value={query}
          onChange={(e) => handleChange(e)}
          inputProps={{
            name: "source",
            id: "source-native-helper",
          }}
        >
          {/* <option key="empty" value="">
            Choose source
          </option> */}
          {source &&
            source.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
      <br />

        <Button
          variant="contained"
          color="primary"
          onClick={(e)=>handleSubmit(e)}
          style={{ width: "50%", marginLeft: "25%", marginTop: "15%" }}
        >
          <Link exact style={{ textDecoration: "none", color: "white" }}>
            Submit
          </Link>
        </Button>
      </FormControl>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"New Service added successfully"}
        </DialogTitle>

        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Close
          </Button> */}
          <Button color="primary" autoFocus>
            <Link
              exact
              to="/"
              style={{ textDecoration: "none", color: "#008CBA" }}
            >
              Okay
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(InboundSource);











{/*
  <div className="not-container">
      <form className="issueform" onSubmit={(e) => e.preventDefault()}>
        <div className="issue-dropdown-box">
          <label className="issue">Issue:</label>

          <select
            className="issue-dropdown"
            value={issueId}
            onChange={(e) => setIssueId(e.target.value)}
          >
            {contactissues &&
              contactissues.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className="remarksbox">
          <label className="remarks">Remarks:</label>

          <TextField
            id="outlined-basic"
            variant="outlined"
            multiline
            defaultValue=""
            rows={4.5}
            style={{ marginLeft: 60 }}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </div>

        

        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={(e) => handleClick(e)}
        >
          Submit
        </Button>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Form submited successfully"}
        </DialogTitle>

        <DialogActions>
          
          <Button color="primary" autoFocus>
            <Link
              exact
              to="/"
              style={{ textDecoration: "none", color: "#008CBA" }}
            >
              Okay
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
*/}
