import React, { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import API from "../utils/API";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import NativeSelect from "@mui/material/NativeSelect";
import Radio from "@mui/material/Radio";
// import InterestedCustomerDetails from "./InterestedCustomerDetails";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  select: {
    minHeight: 30,
    background: "#fafafa",
    border: "1px",
    borderBottom: "1px",
    outline: "1px",
    color: "#555555",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    height: "19px",
    lineHeight: "19.0016px",
    margin: "0px",
    padding: "6px 0px 7px",
    width: "366px",
  },
}));

const Leadgenerations = () => {
  const classes = useStyles();
  const history = useHistory();

  const [categoryHead, setcategoryHead] = React.useState();
  const [category, setcategory] = React.useState();
  const [subcategory, setSubcategory] = React.useState();

  const [query, setQuery] = React.useState("1");

  const [query2, setQuery2] = React.useState("7");

  const [query3, setQuery3] = React.useState("2146");

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChangeradio = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChange = (event) => {
    var value = event.target.value;

    setQuery(value);

    console.log(value);

    console.log("query is ", query);
  };

  const handleChange2 = (event) => {
    var value2 = event.target.value;

    console.log("this is value ", value2);
    setQuery2(value2);

    console.log("query 2 is ", query2);
  };

  const handleChange3 = (event) => {
    var value3 = event.target.value;
    console.log("this is value ", value3);
    setQuery3(value3);

    console.log("query 3 is ", query3);
  };

  const location = useLocation();
  const { phonenumber } = location.state;

  var path = {};

  if (selectedValue === "a") {
    path = {
      pathname: "/interestedcustomerdetails/",
      state: {
        query: query,
        query2: query2,
        query3: query3,
        phonenumber: phonenumber,
      },
    };
  } else if (selectedValue === "c") {
    path = {
      pathname: "/notinterestedusers",
      state: {
        phonenumber: phonenumber,
      },
    };
  }

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }
    API({
      url: `/categoryHead/1`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);

        // setCustomerservices(res.data);
        console.log(res.data);
        setcategoryHead(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }
    API({
      url: `/category/1`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);

        // setCustomerservices(res.data);
        console.log(res.data);
        setcategory(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400 || err.response.status === 401) {
          history.push("/");
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");
    if (!storedToken) {
      history.push("/");
    }
    API({
      url: `/category/7`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);

        // setCustomerservices(res.data);
        console.log(res.data);
        setSubcategory(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400 || err.response.status === 401) {
          history.push("/");
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    API({
      url: `category/${query}`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);

        // setCustomerservices(res.data);
        console.log("Hi", res.data);
        setcategory(res.data);
        var q2 = res.data[0].master.id;
        var value = `${q2}`;
        setQuery2(value);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    API({
      url: `category/${query2}`,
      headers: { Authorization: `Bearer ${storedToken}` },
    })
      .then((res) => {
        console.log(res);

        // setCustomerservices(res.data);
        console.log("Hey", res.data);
        setSubcategory(res.data);
        var q3 = res.data[0].master.id;
        var value = `${q3}`;
        setQuery3(value);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [query2]);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <h3 style={{ color: "#555555", marginLeft: -250 }}>Category Head</h3>

        <NativeSelect
          value={query}
          onChange={(e) => handleChange(e)}
          inputProps={{
            name: "categoryhead",
            id: "categoryhead-native-helper",
          }}
        >
          {/* <option key="empty" value="">
            Choose Category Head
          </option> */}
          {categoryHead &&
            categoryHead.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
      <br />

      <FormControl className={classes.formControl}>
        <h3 style={{ color: "#555555", marginLeft: -290 }}>Category</h3>

        <NativeSelect
          value={query2}
          onChange={(e) => handleChange2(e)}
          inputProps={{
            name: "category",
            id: "category-native-helper",
          }}
        >
          {/* <option key="empty" value="">
            Choose Category
          </option> */}
          {category &&
            category.map((item) => (
              <option key={item.master.id} value={item.master.id}>
                {item.master.name}
              </option>
            ))}
        </NativeSelect>
      </FormControl>

      <br />

      <FormControl className={classes.formControl}>
        <h3 style={{ color: "#555555", marginLeft: -256 }}>Sub Category</h3>

        <NativeSelect
          value={query3}
          onChange={(e) => handleChange3(e)}
          inputProps={{
            name: "subcategory",
            id: "subcategory-native-helper",
          }}
        >
          {/* <option key="empty" value="">
            Choose SubCategory
          </option> */}
          {subcategory &&
            subcategory.map((item) => (
              <option key={item.master.id} value={item.master.id}>
                {item.master.name}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
      <br />
      <br />

      <br />

      <div className={classes.formControl} style={{ marginLeft: -10 }}>
        <Radio
          checked={selectedValue === "a"}
          onChange={handleChangeradio}
          value="a"
          name="radio-button-demo"
          inputProps={{ "aria-label": "A" }}
          color="primary"
          label="Interested"
        />
        <span style={{ marginRight: 20 }}>Interested</span>
        <Radio
          checked={selectedValue === "b"}
          onChange={handleChangeradio}
          value="b"
          name="radio-button-demo"
          inputProps={{ "aria-label": "B" }}
          color="primary"
          label="Booking"
        />
        <span style={{ marginRight: 20 }}>Booking</span>
        <Radio
          checked={selectedValue === "c"}
          onChange={handleChangeradio}
          value="c"
          name="radio-button-demo"
          inputProps={{ "aria-label": "C" }}
          color="primary"
          label="Not Interested"
        />
        Not Interested
      </div>

      <br />
      <br />

      <Button variant="contained" color="primary" style={{ marginLeft: -20 }}>
        {selectedValue !== "b" ? (
          <Link to={path} style={{ textDecoration: "none", color: "white" }}>
            Next
          </Link>
        ) : (
          <a
            href="https://repairadda.com/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            Next
          </a>
        )}
      </Button>
    </div>
  );
};

export default withRouter(Leadgenerations);
