import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import API from "../utils/APIOld";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // width: "30ch",
      margin: theme.spacing(1),
      flexGrow: 1,
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    minWidth: "10rem",
  },

  tableRow: {
    height: 30,
  },
  tableCell: {
    padding: "8px 17px 25px 17px",
  },
}));

const styles = {
  customertable: {
    marginTop: 0,
    marginLeft: 400,
    marginBottom: 10,
    paddingTop: 10,
    height: 225,
  },
};

const VendorBalance = () => {
  const history = useHistory();
  const [id, setId] = useState("");
  const [idFromButtonClick, setIdFromButtonClick] = useState();
  const [loading, setLoading] = useState(false);
  const [balance, setVendorBalance] = useState({});
  const [vendorscreen, setVendorscreen] = useState({});
  const classes = useStyles();
  const [tableshow, setTableshow] = useState(false);
  //const [tablescreen, setTablescreen] = useState(false);

  
  const options = { month: "long", day: "numeric", year: "numeric" };

  const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

 // const numberFormatter = new Intl.NumberFormat("en-IN", options);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2
  })



  const columns = [
    { field: "id", headerName: "Transaction ID", width: 250 },
    {
      field: "collectionDate",
      headerName: "Date",
      width: 250,
      //valueFormatter: ({value}) => dateFormatter.format(new Date(value)),
    },
    

    { field: "commTotal", headerName: "Commission", width: 250,
      valueFormatter: ({value}) => formatter.format(value),
    },

    { field: "commReceived", headerName: "Recharge", width: 250,
      valueFormatter: ({value}) => formatter.format(value),
   },
  ];

  const handleClick = () => {
    setLoading(!loading);
    setTableshow(false);
    //setTablescreen(false);
    setIdFromButtonClick(id);
    setVendorBalance({});
    setVendorscreen({});
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }
    if (id !== "") {
      API({
        url: `vendorwalletbalance.html?vendorId=${idFromButtonClick}`,
        headers: { Authorization: `Bearer ${storedToken}` },
      })
        .then((res) => {
          setVendorBalance(res.data);
          setLoading(false);
          setTableshow(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [idFromButtonClick, loading]);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenStore");

    if (!storedToken) {
      history.push("/");
    }
    if (id !== "") {
      API({
        url: `vendorwallethistory.html?vendorId=${idFromButtonClick}`,
        headers: { Authorization: `Bearer ${storedToken}` },
      })
        .then((res) => {
          setVendorscreen(res.data);
          setLoading(false);
          //setTablescreen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [idFromButtonClick, loading]);

  const mapToId = (vendorscreen) =>
    vendorscreen.map((row) => {
      const { tokenNo, ...rest } = row;
      return { id: tokenNo, ...rest };
    });


 

  return (
    <nav>
    <Box style={{ width: "30%", marginLeft: "15rem" }}>
      <div>
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <TextField
            id="outlined-basic"
            label="Enter Vendor ID"
            variant="outlined"
            aria-describedby="my-helper-text"
            //  type="text"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />

          <Button
            type="submit"
            style={{
              padding: "4px",
              marginLeft: "20px",
              marginTop: "10px",
            }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Submit
          </Button>
        </form>

        {tableshow ? (
          <Grid container style={{ width: 670 }}>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={styles.customertable}>
                <Table className={classes.table} aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ padding: "20px 20px" }}>
                        {" "}
                        {/* <center> */}
                        <b>Vendor Id: </b> {balance.vendorId}
                        {/* </center> */}
                      </TableCell>
                      <TableCell style={{ transform: "translateX(-5%)" }}>
                        {" "}
                        {/* <center> */}
                        <b>Wallet Balance: </b> {balance.walletBalance} <br />
                        {/* </center> */}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} style={{ padding: "25px 20px" }}>
                        {" "}
                        {/* <center> */}
                        <b>Vendor Name: </b> {balance.vendorName}
                        {/* </center> */}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: "25px 20px" }}>
                        {" "}
                        {/* <center> */}
                        <b>Pending Collection: </b>
                        {balance.pendingCollection}
                        {/* </center> */}
                      </TableCell>
                      <TableCell style={{ transform: "translateX(-5%)" }}>
                        <b>Total Collection: </b>
                        {balance.totalCollection}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        style={{ borderBottom: "none", padding: "25px 20px" }}
                      >
                        <b>Commission Received:</b>
                        {balance.commissionReceived}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                 
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {loading ? <CircularProgress style={{ marginTop: 50 }} /> : ""}
      </div>
    </Box>

    <Box alignItems="center" style={{ width: "80%", marginLeft: "15rem" }}>


   <div
          style={{
            height: 450,
            width: "100%",
            background: "transparent",
            color: "black",
            marginTop: 20,
            marginLeft: 0,
          }}
        >
          {vendorscreen.length > 0 && (
            <DataGrid
              rows={mapToId(vendorscreen)}
              columns={columns}
              pageSize={10}
              sortingOrder={["desc", "asc"]}
              
              
            />
          )}

         
        </div>
       

   {/* {tablescreen ? (
          <Grid container style={{ width: 450 }}>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={styles.customertable}>
                <Table className={classes.table} aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ padding: "20px 20px" }}>
                        {" "}
                        
                        <b> Date: </b> {balance.collectionDate}
                       
                      </TableCell>
                      <TableCell style={{ transform: "translateX(-5%)" }}>
                        {" "}
                        
                        <b>Transaction ID: </b> {} <br />
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ) : (
          ""
        )}*/}




    </Box>

    </nav>
  );
};




export default VendorBalance;
